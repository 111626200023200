/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';

// Breakpoints
const mediaQueryLaptop = window.matchMedia('(min-width: 1024px)');

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--vh', `${document.documentElement.clientHeight}px`);
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Custom animate On Scroll
var toAnimate = document.querySelectorAll('.has-animation');
if (toAnimate.length) {

	var handleIntersection = function (entries) {

		entries.map((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add("animate");
				observer.unobserve(entry.target);
			}
		});
	};

	const options = {
		threshold: 0.1
	};

	const observer = new IntersectionObserver(handleIntersection, options);

	toAnimate.forEach(el => observer.observe(el));
}

// Same height
if (document.querySelectorAll('.same-height').length) {
	(async () => {
		const ResponsiveAutoHeight = (await import("responsive-auto-height")).default;
		new ResponsiveAutoHeight('.same-height');
	})();
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav'), navigation = document.querySelector('.nav'), navigationContainer = document.querySelector('.nav');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			//focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			//focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Slider block
var sliders = document.querySelectorAll('.content-block.is-image-slider, .content-block.is-partner-slider');
if (sliders.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		sliders.forEach(el => {
			if (el.querySelectorAll('.column').length > 1 || el.querySelectorAll('.swiper-slide').length > 1) {
				const columnBlock = el.querySelector('.image-slider, .columns'),
					swiperItem = columnBlock.querySelectorAll('.swiper-slide, .column'),
					swiperContainer = document.createElement('div'),
					swiperButtonPrev = document.createElement('div'),
					swiperButtonNext = document.createElement('div');

				if (columnBlock.classList.contains('image-slider')) {
					swiperContainer.setAttribute('class', 'swiper-container image-slider');
				}
				else if (columnBlock.classList.contains('partners')) {
					swiperContainer.setAttribute('class', 'swiper-container partners');
				}

				if (columnBlock.classList.contains('partners')) {
					columnBlock.setAttribute('class', 'swiper-wrapper lego-blocks');
				}
				else {
					columnBlock.setAttribute('class', 'swiper-wrapper');
				}

				columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
				swiperContainer.appendChild(columnBlock);

				swiperButtonPrev.setAttribute('class', 'swiper-button-prev');
				swiperButtonNext.setAttribute('class', 'swiper-button-next');
				swiperContainer.appendChild(swiperButtonNext);
				swiperContainer.appendChild(swiperButtonPrev);

				swiperItem.forEach(si => {
					si.classList.add('swiper-slide');
					si.classList.remove('same-height');
					si.classList.remove('column');
					si.style.height = null;
				});

				var spaceBetween = 20,
					slidesOffsetAfter = 0;

				if (columnBlock.classList.contains('lego-blocks')) {
					spaceBetween = 48;
					slidesOffsetAfter = 38;
				}

				new Swiper(el.querySelector('.swiper-container'), {
					slidesPerView: 'auto',
					allowTouchMove: true,
					keyboard: {
						enabled: true
					},
					spaceBetween: spaceBetween,
					slidesOffsetAfter: slidesOffsetAfter,
					navigation: {
						nextEl: swiperButtonNext,
						prevEl: swiperButtonPrev
					}
				});
			}
		});
	})();
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal], .lg-modal',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal], .lg-modal');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Home video
const videoModalInWrapper = document.querySelector('.video-wrapper__modal.in-wrapper');

if (videoModalInWrapper) {
	var video = document.getElementById(videoModalInWrapper.dataset.html);
	var vimeoVideo = document.getElementById('vimeo-video');
	var videoLoop = document.querySelector('.video.is-loop-video');
	var videoBackground = document.querySelector('.video-background');

	if (video) {
		video.pause();

		videoModalInWrapper.addEventListener("click", e => {
			if (mediaQueryLaptop.matches) {
				video.style.opacity = "1";
				video.loop = true;
				video.playsinline = true;
				video.controls = true;
				video.play();
				videoModalInWrapper.style.display = 'none';
				videoModalInWrapper.style.pointerEvents = 'initial';

				if (videoLoop) {
					videoLoop.style.opacity = "0";
				}

				if (videoBackground) {
					videoBackground.style.opacity = "0";
				}
			}
		});
	}

	if (vimeoVideo) {
		videoModalInWrapper.addEventListener("click", e => {
			if (mediaQueryLaptop.matches) {
				vimeoVideo.src = vimeoVideo.src.replace('autoplay=0', 'autoplay=1');
				vimeoVideo.style.opacity = "1";
				vimeoVideo.style.pointerEvents = 'initial';
				videoModalInWrapper.style.display = 'none';
			}

			if (videoLoop) {
				videoLoop.style.opacity = "0";
			}

			if (videoBackground) {
				videoBackground.style.opacity = "0";
			}
		});
	}
}

// Lazy load video
var lazyVideos = document.querySelectorAll('.video.lazy');
if (lazyVideos.length) {
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
}

// Dropdown
const dropdowns = document.querySelectorAll('.dropdown-checkbox');
if (dropdowns.length) {
	var activeDropdown = null;
	var activeOption = null;

	var onDropdownChange = function (el) {
		if (activeDropdown !== null) {
			activeDropdown.checked = false;
		}
		if (el === activeDropdown) {
			activeDropdown = null;
		}
		else {
			activeDropdown = el;
		}
	};

	var updateLabel = function (option, label, associatedDropdown) {
		if (activeOption === option) {
			setTimeout(function () { option.checked = false; }, 50);
			activeOption = null;
			label.innerHTML = label.dataset.placeholder;
			associatedDropdown.classList.remove('has-value');
		}
		else {
			activeOption = option;
			label.innerText = option.value;
			associatedDropdown.classList.add('has-value');
		}
	};

	dropdowns.forEach(el => {
		el.addEventListener("change", async function (e) {
			onDropdownChange(el);
		});
	});

	document.addEventListener('click', e => {
		if (e.target.closest('.dropdown-checkbox') === null && e.target.closest('.dropdown') === null) {
			if (activeDropdown !== null) {
				onDropdownChange(activeDropdown);
			}
		}
	});
}
const dropdownContents = document.querySelectorAll('.dropdown__content');
if (dropdownContents.length) {
	dropdownContents.forEach(el => {
		window.addEventListener("load", function () {
			setTimeout(function () { el.parentElement.style.height = el.offsetHeight + "px"; }, 200);
		});
		window.addEventListener("resize", function () {
			setTimeout(function () { el.parentElement.style.height = el.offsetHeight + "px"; }, 200);
		});
	});
}

// Modal overview
var modalContainers = document.querySelectorAll('.modal-overview');
if (modalContainers.length) {

	modalContainers.forEach(el => {
		var culture = document.documentElement.lang;

		el.addEventListener('click', event => {
			var modalItem = event.target.closest('.modal-item');
			if (modalItem) {
				getModal('/umbraco/Surface/Modal/Info?id=' + modalItem.id + '&culture=' + culture + '&pageId=' + el.dataset.pageId, el);
			}
		});
	});
}

function getModal(fetchUrl, overview) {

	fetch(fetchUrl, {
		method: 'get',
		headers: {
			'X-Requested-With': 'XMLHttpRequest'
		}
	})
		.then(response => response.text())
		.then(data => {
			var parser = new DOMParser();
			var newModal = parser.parseFromString(data, 'text/html').querySelector('.modal');
			var modalContainer = document.querySelector('.modal-container');

			if (modalContainer) {
				var oldModal = modalContainer.querySelector('.modal');
				oldModal.classList.remove('fade-in');
				setTimeout(function () { oldModal.remove(); modalContainer.appendChild(newModal); }, 500);
				setTimeout(function () { newModal.classList.add('fade-in'); }, 550);
			}
			else {
				modalContainer = document.createElement('div');
				modalContainer.classList.add('modal-container');
				document.body.appendChild(modalContainer);
				modalContainer.appendChild(newModal);

				setTimeout(function () { modalContainer.classList.add('fade-in'); }, 50);
				setTimeout(function () { newModal.classList.add('fade-in'); }, 500);

				modalContainer.addEventListener('click', event => {
					if (event.target === modalContainer) {
						closeModal(event, modalContainer);
					}
					if (event.target.className === 'modal__close') {
						closeModal(event, modalContainer);
					}
					if (event.target.className === 'modal__prev') {
						getSiblingModal(event, modalContainer, overview, false);
					}
					if (event.target.className === 'modal__next') {
						getSiblingModal(event, modalContainer, overview, true);
					}
					if (event.target.className === 'swiper-button-prev' || event.target.className === 'swiper-button-next') {
						getSiblingModal(event, modalContainer, overview, true);
					}
				});

				document.addEventListener('keydown', event => {
					if (event.keyCode === 27) {
						closeModal(event, modalContainer);
					}
				});
			}
		});
}

function closeModal(e, modalContainer) {
	var modal = modalContainer.querySelector('.modal');
	modal.classList.remove('fade-in');
	setTimeout(function () { modalContainer.classList.remove('fade-in') }, 500);
	setTimeout(function () { modalContainer.remove(); }, 1000);
}

function getSiblingModal(e, modalContainer, overview, getNext) {
	var modal = modalContainer.querySelector('.modal');
	var id = modal.dataset.modalId;
	var modalItems = overview.querySelectorAll('.modal-item');
	var modalItemsCount = modalItems.length;
	var currentModelItemIndex = 0;
	var nextModalItemToShow = null;

	for (var i = 0; i < modalItemsCount; i++) {
		if (modalItems[i].id === id) {
			currentModelItemIndex = i;
		}
	}

	if (getNext) {
		nextModalItemToShow = modalItems[currentModelItemIndex + 1];
		if (nextModalItemToShow === null) {
			nextModalItemToShow = modalItems[0];
		}
	}
	else {
		nextModalItemToShow = modalItems[currentModelItemIndex - 1];
		if (nextModalItemToShow === null) {
			nextModalItemToShow = modalItems[(modalItemsCount - 1)];
		}
	}

	if (nextModalItemToShow !== null) {
		getModal('/umbraco/Surface/Modal/Info?overviewId=' + overview.id + '&id=' + nextModalItemToShow.id + '&culture=' + document.documentElement.lang, overview);
	}
}

//Application filter
const filter = document.querySelector('.filter');
const overview = document.querySelector('.overview');
const resultOverview = document.querySelector('.overview .columns');
if (filter && overview) {

	var filterFunction = function (e) {

		// Get the checked inputs from the form
		const data = new URLSearchParams(new FormData(filter));

		// Get new overview
		fetch(window.location.pathname, {
			method: "post",
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		})
			.then(response => response.text())
			.then(data => {

				// Empty overview
				resultOverview.innerHTML = '';

				// Create DOM
				var parser = new DOMParser();
				var doc = parser.parseFromString(data, 'text/html');
				var hasItems = false;

				// Add items with animation
				doc.querySelectorAll('.column').forEach(function (el) {
					resultOverview.appendChild(el);
					var overviewItem = el.querySelector('.lego-block');
					if (overviewItem) {
						overviewItem.classList.add('animate');
					}
					else {
						el.classList.add('animate');
					}
				});

				addClickEventToApllicationTags();
			});

		// Update the url
		if (history.pushState) {

			if (data.toString() === "") {
				window.history.replaceState({}, '', `${location.pathname}`);
			}
			else {
				window.history.replaceState({}, '', `${location.pathname}?${data}`);
			}
		}
	};

	// Get all the inputs
	var inputs = filter.querySelectorAll('input.filter__input');
	inputs.forEach(el => {

		// Add on change event to inputs
		el.addEventListener("change", async function (e) {
			filterFunction(e);

			// Clear tag filter
			if (el.classList.contains('is-tag')) {
				var tagWrapper = el.closest('.tags-wrapper');
				if (tagWrapper) {
					tagWrapper.remove();
				}
			}

			e.preventDefault();
		});
	});

	// Reset the filter
	var filterReset = filter.querySelector('.filter__reset');
	if (filterReset) {
		filterReset.addEventListener('click', function (e) {
			e.preventDefault();
			inputs.forEach(i => {
				i.checked = false;
				// Clear tag filter
				if (i.classList.contains('is-tag')) {
					var tagWrapper = i.closest('.tags-wrapper');
					if (tagWrapper) {
						tagWrapper.remove();
					}
				}
			});
			filterFunction(e);
		});
	}
}

// Application overview tags
function addClickEventToApllicationTags() {
	const overviewTags = document.querySelectorAll('.overview .tag');
	if (overviewTags.length) {
		overviewTags.forEach(t => {
			t.addEventListener('click', function (e) {
				e.stopPropagation();
				e.preventDefault();
				var newUrl = t.dataset.url;
				//console.log(newUrl);
				if (newUrl) {
					window.location.href = newUrl;
				}
			});
		});
	}
}
addClickEventToApllicationTags();

// Jump to submitted form
document.querySelectorAll('.content-block.is-form-block').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form && button) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
			button.disabled = true;
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

// Animte highlighted products
var videoWrapper = document.querySelectorAll('.video-wrapper');
if (videoWrapper.length) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;

		videoWrapper.forEach(el => {
			var videoButtonModal = el.querySelectorAll('.video-wrapper__modal.lg-modal');
			var videoButtonWrapper = el.querySelectorAll('.video-wrapper__modal.in-wrapper');

			var timeline = gsap.timeline({
				scrollTrigger: {
					trigger: el,
					start: "+=-200px",
					end: () => "+=" + (el.clientHeight / 2),
					scrub: true,
					//markers: true
				}
			})
				.add('videoButtonScroll')
				.fromTo(videoButtonModal, { y: 0 }, { y: (el.clientHeight / 2) }, 'videoButtonScroll')
				.fromTo(videoButtonWrapper, { y: 0 }, { y: (el.clientHeight / 2) }, 'videoButtonScroll');


		});
	})();
}